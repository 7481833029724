<template>
  <div>
    <div class="orgContainer">
      <div
        v-if="orgData && orgData.urls && orgData.urls.banner"
        class="orgContainer__banner"
      >
        <img
          class="orgContainer__banner__image"
          :src="orgData.urls.banner"
          alt="banner"
        >
      </div>
      <div :class="`wrapper__box ${orgData.logo === undefined && 'hideLogo'}`">
        <div class="wrapper__orgBar">
          <img
            class="wrapper__orgBar__boxImage"
            :src="orgData.logo"
            style="width: 100%"
            alt="logo">
        </div>
      </div>
      <div class="wrapper__box__container">
        <div class="wrapper__box__content">
          <h1>{{ orgData.name }}</h1>
          <!-- <div v-if="orgData.year_founded">
            Founded in {{ orgData.year_founded }}
          </div>
          <div v-else>
            Founded in {{ year }}
          </div> -->
          <div
            v-if="orgData.description"
            class="wrapper__box__boxInner">
            <!-- <div class="wrapper__box__boxInnerContent">
              {{ orgData.description }}
            </div> -->
            <vue-markdown class="wrapper__box__boxInnerContent">{{orgData.description}}</vue-markdown>
          </div>
          <div class="tags_row">
            <router-link
              v-for="(item, index) in orgData.industry"
              :key="index"
              class="tags"
              :to="`/tag/${item}`">
              {{ '#' + item }}
            </router-link>
          </div>
          <div
            v-if="orgData.address"
            class="wrapper__box__boxInner">
            <!-- <div class="wrapper__box__boxInnerContent">
              {{ orgData.address.street_address }}, {{ orgData.address.city }},
              {{ orgData.address.country }}, {{ orgData.address.postcode }}
            </div> -->
            <div
              v-if="orgData.urls"
              class="wrapper__box__boxInnerContent">
              <a
                v-if="orgData.urls.website"
                target="_blank"
                :href="orgData.urls.website.includes('http')
                  ? orgData.urls.website
                  : `//${orgData.urls.website}`"
              >
                <i class="urls fal fa-link" />
              </a>
              <a
                v-if="orgData.urls.facebook"
                target="_blank"
                :href="orgData.urls.facebook.includes('http')
                  ? orgData.urls.facebook
                  : `//${orgData.urls.facebook}`"
              >
                <i class="urls fab fa-facebook-f" />
              </a>
              <a
                v-if="orgData.urls.instagram"
                target="_blank"
                :href="orgData.urls.instagram.includes('http')
                  ? orgData.urls.instagram
                  : `//${orgData.urls.instagram}`"
              >
                <i class="urls fab fa-instagram" />
              </a>
              <a
                v-if="orgData.urls.linkedin"
                target="_blank"
                :href="orgData.urls.linkedin.includes('http')
                  ? orgData.urls.linkedin
                  : `//${orgData.urls.linkedin}`"
              >
                <i class="urls fab fa-linkedin-in" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <!-- <div class="box" /> -->

      <!-- Tabs -->
      <div class="tabs">
        <!-- Tab items -->
        <ul class="tabs__list">
          <li
            class="tabs__item"
            :class="{ 'tabs__item--active': tab.id === activeTab }"
            v-for="tab in tabItems"
            :key="tab.id"
            @click="handleSelectTab(tab.id, tab.slug)"
          >
            {{ tab.label }}
          </li>
        </ul>

        <!-- Tab content -->
        <ul class="tabs__content-list">
          <li class="tabs__content" v-show="activeTab === 'products'">
            <ais-instant-search
              :search-client="algoliaClient"
              :index-name="algoliaIndex"
            >
                <Listing
                  :fromOwner="orgData.name"
                  :displayModal="true"
                  :userLogged="$store.getters.userLoggedIn"
                  :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
                  :disabledCollections="['vk_icecream']"
                  @addItemToCart="$emit('addItemToCart', $event)"
                  @openSnackbar="$emit('openSnackbar', $event)"
                />
            </ais-instant-search>
          </li>
          <li class="tabs__content" v-show="activeTab === 'shop'">
            <ais-instant-search
              :search-client="algoliaClient"
              :index-name="algoliaIndex"
            >
              <Listing
                :offeringsFromOwner="orgData.id"
                :displayModal="true"
                :userLogged="$store.getters.userLoggedIn"
                :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
                :disabledCollections="['vk_icecream']"
                @addItemToCart="$emit('addItemToCart', $event)"
                @openSnackbar="$emit('openSnackbar', $event)"
              />
            </ais-instant-search>
          </li>
          <li class="tabs__content" v-if="activeTab === 'recipes'">
            <ais-instant-search
              :search-client="algoliaClient"
              :index-name="algoliaIndex"
            >
              <Listing
                itemType="recipe"
                :fromOwner="orgData.name"
                :userLogged="$store.getters.userLoggedIn"
                :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
                :disabledCollections="['vk_icecream']"
                @addItemToCart="$emit('addItemToCart', $event)"
                @openSnackbar="$emit('openSnackbar', $event)"
              />
            </ais-instant-search>
          </li>
          <li class="tabs__content" v-if="activeTab === 'news'">
            <ais-instant-search
              :search-client="algoliaClient"
              :index-name="algoliaIndex"
            >
              <Listing
                itemType="article"
                :fromOwner="orgData.name"
                :userLogged="$store.getters.userLoggedIn"
                :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
                :disabledCollections="['vk_icecream']"
                @addItemToCart="$emit('addItemToCart', $event)"
                @openSnackbar="$emit('openSnackbar', $event)"
              />
            </ais-instant-search>
          </li>
          <li class="tabs__content" v-if="activeTab === 'posts'">
            <ais-instant-search
              :search-client="algoliaClient"
              :index-name="algoliaIndex"
            >
              <Listing
                itemType="post"
                :fromOwner="orgData.name"
                :userLogged="$store.getters.userLoggedIn"
                :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
                :disabledCollections="['vk_icecream']"
                @addItemToCart="$emit('addItemToCart', $event)"
                @openSnackbar="$emit('openSnackbar', $event)"
              />
            </ais-instant-search>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/services/httpClient'
import { algoliaClient } from '@kiukicom/listing'
import VueMarkdown from 'vue-markdown'
import siteConstants from '@/const.js'

export default {
  components: {
    VueMarkdown
  },
  data () {
    return {
      siteConstants,
      algoliaClient,
      algoliaIndex: process.env.VUE_APP_ALGOLIA_INDEX,
      orgData: {},
      orgProducts: [],
      orgOfferings: [],
      orgRecipes: [],
      orgArticles: [],
      orgPosts: [],
      activeTab: 'products',
      urlToTab: {
        'own-label': 'products',
        'shop': 'shop',
        'recipes': 'recipes',
        'news': 'news',
        'posts': 'posts'
      },
      tabItems: []
    }
  },
  computed: {
    year () {
      return this.orgData.created_at.slice(0, 4)
    }
  },
  created () {
    this.getOrgData()
    this.getOrgProducts()
  },
  methods: {
    getSeletedTabFromUrl () {
      const { tab } = this.$route.query
      if (tab === null || tab === undefined || tab.length === 0) { return }
      if (this.urlToTab[tab] !== undefined) {
        this.activeTab = this.urlToTab[tab]
      } else {
        this.$router.replace({ query: { } })
      }
    },
    handleSelectTab (tabId, tabSlug) {
      this.activeTab = tabId
      this.$router.replace({ query: { tab: tabSlug } })
    },
    getOrgData () {
      const { slug } = this.$route.params
      httpClient.get(`/org/${slug}`).then((response) => {
        this.orgData = response.data
        // console.log(response.data.urls)
      }).catch(() => {
        // this.$router.replace({ name: '404' })
        this.$store.commit('updateNotFound')
      })
    },
    getOrgProducts () {
      const { slug } = this.$route.params
      let items = []

      httpClient.get(`/org/${slug}/items-status`).then((resp) => {
        // TODO: Improve to check articles / recipes / posts
        const { data } = resp
        if (data.packagedfood) {
          this.orgProducts.push({})
        }
        if (data.productoffering) {
          this.orgOfferings.push({})
        }
        if (data.recipe) {
          this.orgRecipes.push({})
        }
        if (data.article) {
          this.orgArticles.push({})
        }
        if (data.post) {
          this.orgPosts.push({})
        }

        if (this.orgOfferings.length !== 0) {
          items.push({ id: 'shop', label: `${this.orgData.name !== undefined ? this.orgData.name : ''} Shop`, slug: 'shop' })
          this.activeTab = 'shop'
          if (this.$route.query.tab === undefined) {
            // Avoids duplicated navigation
            this.$router.replace({ query: { tab: 'shop' } })
          }
        }
        this.orgProducts.length !== 0 && items.push({ id: 'products', label: 'Own Label', slug: 'own-label' })
        this.orgRecipes.length !== 0 && items.push({ id: 'recipes', label: 'Recipes', slug: 'recipes' })
        this.orgArticles.length !== 0 && items.push({ id: 'news', label: 'News', slug: 'news' })
        this.orgPosts.length !== 0 && items.push({ id: 'posts', label: 'Posts', slug: 'posts' })

        this.tabItems = items
      }).catch(err => console.log(err))
        .finally(() => {
          this.getSeletedTabFromUrl()
        })

      // httpClient.get(`/org/${slug}/items?format=full`).then((response) => {
      //   // for (const el of response.data) {
      //   //   switch (el.item_type) {
      //   //     case 'packagedfood':
      //   //       this.orgProducts.push(el)
      //   //       break
      //   //     case 'productoffering':
      //   //       this.orgOfferings.push(el)
      //   //       break
      //   //     case 'article':
      //   //       this.orgArticles.push(el)
      //   //       break
      //   //     case 'recipe':
      //   //       this.orgRecipes.push(el)
      //   //       break
      //   //     case 'post':
      //   //       this.orgPosts.push(el)
      //   //       break
      //   //     default:
      //   //       break
      //   //   }
      //   // }

      //   let items = []

      //   if (this.orgOfferings.length !== 0) {
      //     items.push({ id: 'shop', label: `${this.orgData.name !== undefined ? this.orgData.name : ''} Shop`, slug: 'shop' })
      //     this.activeTab = 'shop'
      //     if (this.$route.query.tab === undefined) {
      //       // Avoids duplicated navigation
      //       this.$router.replace({ query: { tab: 'shop' } })
      //     }
      //   }
      //   this.orgProducts.length !== 0 && items.push({ id: 'products', label: 'Own Label', slug: 'own-label' })
      //   this.orgRecipes.length !== 0 && items.push({ id: 'recipes', label: 'Recipes', slug: 'recipes' })
      //   this.orgArticles.length !== 0 && items.push({ id: 'news', label: 'News', slug: 'news' })
      //   this.orgPosts.length !== 0 && items.push({ id: 'posts', label: 'Posts', slug: 'posts' })

      //   this.tabItems = items
      // }).finally(() => {
      //   this.getSeletedTabFromUrl()
      // })
    },
    getTags () {}
  },
  metaInfo () {
    const jsonld = {
      name: this.orgData.name,
      type: 'Organization',
      description: this.orgData.description,
      image: this.orgData.logo,
      email: this.orgData.contact_email,
      telephone: this.orgData.contact_phone,
      address: {
        '@type': 'PostalAddress',
        'streetAddress': this.orgData.address && this.orgData.address.street_address,
        'addressLocality': this.orgData.address && this.orgData.address.city
      }
    }
    return {
      title: this.orgData.name,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.orgData.tags ? this.orgData.tags.join`,` : '' },
        // OpenGraph data
        { property: 'og:title', content: this.orgData.name + ` - ${siteConstants.siteName}` },
        { property: 'og:site_name', content: `${siteConstants.siteName}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${siteConstants.siteUrl}/` + this.orgData.slug },
        { property: 'og:image', content: this.orgData.logo },
        { property: 'og:description', content: this.orgData.description },
        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.orgData.name + ` - ${siteConstants.siteName}` },
        { name: 'twitter:description', content: this.orgData.description },
        { name: 'twitter:image:src', content: this.orgData.logo },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.orgData.name + ` - ${siteConstants.siteName}` },
        { itemprop: 'description', content: this.orgData.description },
        { itemprop: 'image', content: this.orgData.logo }
      ],
      script: [{
        type: 'application/ld+json',
        json: jsonld
      }]
    }
  }
}
</script>

<style lang="stylus">
.orgContainer {
  padding 40px 25px 15px 25px
  display: flex;
  flex-wrap: wrap;
  background: white;
  border: 3px solid white;
  border-radius: 30px;
  align-items: center;

  &__banner {
    max-height: 400px;
    width: 100%

    &__image {
      max-width: 100%;
      max-height: 400px;
    }
  }

  .hideLogo {
    display: none
  }

  @media screen and (max-width: 560px) {
    display grid
  }
}
.wrapper__box__container {
  width: 70%

  @media screen and (max-width: 560px) {
    width: 100%
  }
}
.tabs {
  // width: 100%
  display block
  grid-column 1 / -1
  padding 0px 25px 15px 25px
  text-align: left;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-top 1px solid #ddd
  }

  &__item {
    padding: 10px
    text-transform: capitalize
    cursor: pointer;
    border-top: 1px solid transparent;
    font-size: 15px;
    min-width 120px
    text-align center
    margin 0 10px
    color #888
    font-size 13px

    @media screen and (max-width: 400px) {
      font-size: 13px;
      padding: 5px;
    }

    &--active {
      border-top-color: #000;
      color: #000;
    }
  }

  &__content-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 20px
    padding: 5px

    @media screen and (max-width: 400px) {
      margin-top: 10px
    }
  }
}

.wrapper
  color #000
  display grid
  grid-gap 1em
  grid-template-areas "orgBar" "content" "productCards"
  &__box
    width 30%
    padding 10px
    font-size 150%
    max-width 300px

    @media screen and (max-width: 560px) {
      width 100%
    }
    &__content
      padding-left 30px
      display flex
      align-items flex-start
      flex-direction column
      grid-area content
      h1 {
        font-family: 'Bebas Neue', sans-serif;
      }
    &__boxInner
      display flex
      align-items flex-start
      flex-direction column
      font-size 1rem
    &__boxInnerContent
      font-size 0.9rem
      text-align left
      margin-top 10px
  &__orgBar
  width 30%
    grid-area orgBar
    &__boxImage
      object-fit contain
      width 100%
      height 100%
  &__productCards
    display flex
    flex-flow row wrap
    grid-area productCards
    background #fff
    justify-content flex-start
    border-bottom 1px solid #ddd
    margin-top 10px
    padding-bottom 1rem
  h1
    font-size 22px
    font-weight 400
    margin-bottom 3px
  h2
    font-size 15px
    font-weight 400
    margin-top 0
    color #666

.urls
  margin 10px
  color: grey

.row
  display grid
  justify-content center
  align-items center
  margin 0 auto
  gap 30px
  width 100%
  grid-template 1fr / 1fr 1fr 1fr 1fr
  padding-bottom 10px

.tags
  font-size 12px
  color #00b1ab
  cursor pointer
  margin-right 8px
  &_row
    margin 13px 0
    width 100%
    justify-content flex-start
    display flex
    flex-flow row wrap

@media only screen and (min-width: 500px)
  .wrapper
    grid-template-columns 40% auto
    grid-template-areas "orgBar  content" "productCards   productCards"

@media only screen and (min-width: 600px)
  .wrapper
    grid-gap 20px
    grid-template-columns 320px auto
    grid-template-areas "orgBar content" "productCards  productCards  productCards"
    max-width 100%
@media screen and (max-width: 955px)
  .row
    grid-template 1fr / 1fr 1fr 1fr
@media screen and (max-width: 780px)
  .row
    grid-template 1fr / 1fr 1fr
    max-width 460px
@media screen and (max-width: 500px)
  .row
    gap 10px
@media screen and (max-width: 450px)
  .row
    gap 0
    row-gap 1rem
@media screen and (max-width: 435px)
  .row
    grid-template 1fr / 1fr
    max-width 460px
</style>
